<template>
  <div>
    <div class="d-flex">
      <div
        class="flex-grow-1 my-auto"
        :class="$vuetify.breakpoint.mdAndUp ? 'mx-12' : 'mx-2'"
      >
        <p class="mb-0 text-justify">
          At the beginning of the 21st century, the genre of basic Bengali music was blessed with a bunch of fresh talents. With the emergence of FM Radio stations and CDs, those newcomers convincingly took place in the heart of every music-loving Bengalee just like what used to happen during the Golden Era of Modern Bengali Songs (50s-80s). Goutam Ghosal is one of the most decorated and illustrious musicians among them. As a singer-songwriter-composer, Goutam Ghosal has contributed almost 500 new songs to the treasure of Basic Bengali Songs.
          <br /> <br />
          More than half a dozen Albums of Bengali Songs are already in Goutam Ghosal's credit. He has created a number of masterpieces that will adequately quench the thirst of music lovers decade after decade. Many of his mind-blowing lyrics and compositions have enriched a good many albums of some senior and contemporary vocalists, as well as a flock of young talents. His grip on Indian classical and semi-classical genres along with contemporary and folk music makes him an extraordinarily unique musician.
          <br /><br />
          Despite being such a stalwart of the Bengali music world, Goutam Ghosal is not celebrated as much as he deserves. His creations have still not been preserved with a good spirit of appreciation. Goutam Ghosal, on the other hand, does not prefer to reveal himself much in the limelight. But that should not be a hindrance for the new generation to know who he is and what his great creations are. This website, created by an admirer of Goutam Ghosal, is an honest and earnest effort to archive the pearls spreading all over the digital platform.
          <br /><br />
        </p>
        <div class="text-center font-weight-light" style="font-size: 1.5rem">Welcome to the World of <br /> Goutam Ghosal
          <hr style="width: 50px; border-width: 2px;" class="mt-3 mx-auto">
        </div>
      </div>
      <v-img
        v-if="$vuetify.breakpoint.mdAndUp"
        :src="require('@/assets/img/site/dp1.jpg')"
        width="420"
      />
    </div>
    <div v-if="!$vuetify.breakpoint.mdAndUp">
      <v-img
        :src="require('@/assets/img/site/dp1.jpg')"
        width="360"
        contain
        class="mx-auto mt-6"
      />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({})
}
</script>
